<template>
  <b-row>
      <b-col class="col-12">
        <b-button variant="primary" size="sm" @click="addProgress" v-if="validReport && (status == 1 || status == 2)">Buat Laporan</b-button><br/><br/>
      <b-card>
        <b-tabs content-class="mt-2">
          <feather-icon  style="margin-left:20px; color: white" icon="RefreshCwIcon" class="cursor-pointer" @click="getProgress"/>
          <b-tab active title="Laporan Anggota">
            <loading v-if="Loading"></loading>
            <div v-if="!Loading">
              <h1>Hasil Laporan</h1>
              <div v-if="progress.length == 0" style="text-align: center;">
                <br/>
                <br/>
                <br/>
                - Belum Ada Progress -
              </div>
              <ul v-else>
                <li  v-for="(list, index) in progress" :key="index" :style="{ '--accent-color': list.color }">
                  <div class="date">{{ list.created_by_name }}</div>
                  <div class="title"><feather-icon icon="CalendarIcon"></feather-icon> {{ list.formated_created_date }} 
                    <b-badge variant="success" size="lg" v-if="list.approved_by != null"> Approved </b-badge>
                    <br/>
                    <label v-if="list.suspect != null">Suspect A/N = {{ list.suspect }}</label> |  <i v-if="list.loker">Loker {{list.loker}}</i>
                  </div>
                  <div class="descr" v-html="list.description"></div>
                  <div>
                    <span v-if="list.image_url != null" @click="zoomin(list.image_url)"><b-button size="sm" variant="primary"><feather-icon icon="ZoomInIcon"></feather-icon></b-button></span>&nbsp;
                    <span v-if="validUser && list.approved_by == null"><b-button size="sm" variant="success" @click="approveReport(list)"><feather-icon icon="CheckIcon"></feather-icon> Approve Report</b-button></span>&nbsp;
                    <span v-if="list.approved_by == null && (userlogin == list.created_by)"><b-button size="sm" variant="warning" @click="editReport(list)"><feather-icon icon="EditIcon"></feather-icon> Revisi</b-button></span>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
          <b-tab title="Activity Log" v-if="validLog">
            <b-table small :items="tableLog" :fields="tableFields"></b-table>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>

    <b-modal v-model="modalProgress" title="Add Laporan" hide-footer size="lg" centered>
      <validation-observer ref="addprogress" tag="form" @submit.prevent="submitAddProgress">
        <loading v-if="Loading"/>
        <b-form v-if="!Loading" @submit.prevent="submitAddProgress">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Nama Suspect">
                <validation-provider #default="{ errors }" name="suspect" rules="">
                  <b-form-input id="nama suspect" v-model="formProgress.suspect" placeholder="Tidak Wajib di isi. kosongkan bila tidak ada" ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Nomor Loker">
                <validation-provider #default="{ errors }" name="nomor loker" rules="">
                  <b-form-input id="nomor loker" v-model="formProgress.loker" placeholder="Tidak Wajib di isi. kosongkan bila tidak ada" ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="image">
                <validation-provider #default="{ errors }" name="image" rules="">
                  <b-form-input id="image" v-model="formProgress.image_url" placeholder="Tidak Wajib di isi. kosongkan bila tidak ada" ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <validation-provider #default="{ errors }" name="description" rules="required">
                  <quill-editor v-model="content" :options="editorOptions" style="height: 200px;"></quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <br/>
          <br/>
          <br/>
          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal v-model="modalImage" title="Photo" hide-footer size="lg" centered>
      <div class="d-flex justify-content-center">
        <img :src="selectedImage" alt="Image" class="img-thumbnail mb-3" style=" max-width: 100%; cursor: zoom-in">
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from "vue-multiselect";
import { ModelSelect } from "vue-search-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    ModelSelect,
    axios,
    quillEditor
  },
  data() {
    return {
      userlogin: localStorage.getItem('identifier'),
      perPage: 5, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      Loading: false,
      status: false,
      modalProgress: false,
      modalImage: false,
      validRevisi: false,
      progress: [],
      selectedImage: null,
      test: null,
      isEdit: false,
      formProgress: _interfaces.progress_penanganan,
      colors : ["#41516C", "#FBCA3E", "#E24A68", "#1B5F8C", "#4CADAD"],
      content: '',
      tableLog: '',
      tableFields: ['description', 'inserted_by_name','inserted_datetime'],
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'list': 'bullet' }] // Tambahkan tombol untuk membuat daftar tak berurut dan berurut
          ]
        }
      }
    };
  },
  props: {
    validUser: {
      type: Boolean,
      default: false,
    },
    validReport: {
      type: Boolean,
      default: false,
    },
    validLog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init(){
      this.isEdit = false;
      this.getProgress();
      this.getStatus();
      this.getLog();
    },
    getLog(){
      axios.post("idp/penanganan/log",{id:this.$route.params.id}).then((response) => {
        this.tableLog = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      }); 
    },
    getStatus(){
      axios.post("idp/penanganan/status",{id:this.$route.params.id}).then((response) => {
        this.status = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      }); 
    },
    getProgress(){
      this.Loading = true;
      axios.post("idp/penanganan/list-progress",{id_penanganan:this.$route.params.id}).then((response) => {
        this.progress = response.data.data;
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      }); 
    },
    addProgress(){
      this.isEdit = false
      this.modalProgress = true
    },
    submitAddProgress() {
      this.$refs.addprogress.validate().then((success) => {
        if(success){ 

          if(this.isEdit == false){
            this.$swal({
              title: "Tambahkan Laporan Progress?",
              icon: "question",
              background: "black",
              showCancelButton: true,
              confirmButtonColor: "#7367f0",
              cancelButtonColor: "#ea5455",
              confirmButtonText: "Save",
            }).then((result) => {
              if (result.isConfirmed) {  
  
                this.Loading = true;
                setTimeout(() => {
                  this.formProgress.id_penanganan = this.$route.params.id;
                  this.formProgress.description = this.content;
                  axios.post("idp/penanganan/add-progress",this.formProgress).then((response) => {
                    
                  }).catch((error) => {
                    if (error.status === 4000) {
                      localStorage.clear();
                      this.$router.push({ path: "/login" });
                      ({
                        title: "Login Expired",
                        message: "Sesi Login Habis",
                      });
                    }
                  }).finally(() => {
                    this.Loading = false;
                    this.modalProgress = false;
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Add Kasus Berjalan Success",
                        icon: "EditIcon",
                        variant: "success",
                        text: "Kasus Ditambahkan",
                      },
                    });
                    this.init();
                  });
                }, 1000);
  
              } else {
                this.modalProgress = false
              }
            });
          }else{
            this.$swal({
            title: "Revisi Laporan?",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {  

              this.Loading = true;
              setTimeout(() => {
                this.formProgress.id_penanganan = this.$route.params.id;
                this.formProgress.description = this.content;
                axios.post("idp/penanganan/edit-progress",this.formProgress).then((response) => {
                  
                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.Loading = false;
                  this.modalProgress = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Add Kasus Berjalan Success",
                      icon: "EditIcon",
                      variant: "success",
                      text: "Kasus Ditambahkan",
                    },
                  });
                  this.init();
                });
              }, 1000);

            } else {
              this.modalProgress = false
            }
          });
          }

        }
      });
    },
    zoomin(link){
      this.modalImage = true;
      this.selectedImage = link;
    },
    approveReport(list){
      this.$swal({
        title: "Approve Laporan ini?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        if (result.isConfirmed) {  

          this.LoadingAdd = true;
          setTimeout(() => {
            // this.formPenanganan.id = this.$route.params.id;
            
            axios.post("idp/penanganan/approve-report",list).then((response) => {
              
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.LoadingAdd = false;
              this.modalImage = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Submit Success",
                  icon: "EditIcon",
                  variant: "success",
                  text: "Kasus Ditambahkan",
                },
              });
              this.init();
            });
          }, 1000);

        } else {
          this.modalShow = false
        }
      });
    },
    editReport(list){
      this.isEdit = true;
      this.formProgress.id = list.id;
      this.formProgress.suspect = list.suspect;
      this.formProgress.loker = list.loker;
      this.formProgress.image_url = list.image_url;
      this.content = list.description;
      this.modalProgress = true
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>
.header {
  padding: 20px;
}

.header h3 {
  margin: 0;
}

@media (min-width: 768px) {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

h1 {
  text-align: center;
}

ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(100rem, 90%);
  margin-inline: auto;
}

/* line */
ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
ul li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
ul li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
ul li .title,
ul li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}
ul li .title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
ul li .descr {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
ul li .title::before,
ul li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
ul li .title::before {
  bottom: calc(100% + 0.125rem);
}

ul li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 40rem) {
  ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  ul::before {
    grid-column: 2;
  }
  ul li:nth-child(odd) {
    grid-column: 1;
  }
  ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  ul li:nth-child(2) {
    grid-row: 2/4;
  }

  ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.credits {
  margin-top: 1rem;
  text-align: right;
}
.credits a {
  color: var(--color);
}

</style>